import "./Spike.css";

export const Spike = () => {
  return (
    <div className="page_container">
      <div className="grid_container" >
        <div className="item_container hero_item">
          <h1 className="title main_title">
            Oslo City Guide
            <br />
            Kristian Hoff-Andersen
          </h1>
          <h2 className="title sub_title">Architect, writer & guide</h2>
        </div>

        <div className="item_container item_image kristian_tv_img" />
        <div className="item_container item_wide text_item text_bold">
          Discover Oslo with Kristian Hoff-Andersen. I am the only accredited
          architect living in Oslo offering guided tours of the city. Experience
          Oslo's beauty, history and recent developments through the eyes of an
          architect by booking one of several well-crafted tours now.
        </div>

        <div className="item_container item_wide text_item">
          Kristian Hoff-Andersen is an accredited architect living in Oslo. He
          does city walking tours, consultancy, writing and lectures.
          <br />
          <br />
          <br />
          <b>Prices starts at € 100 per hour.</b>
        </div>
        <div className="item_container item_image munch_img" />

        <div className="item_container item_image operaen_img" />
        <div className="item_container item_wide text_item">
          <h3>Contact me</h3>
          For inquiries or special requests, please contact me at:
          <br />
          E-mail:{" "}
          <a href="mailto:kristian@hoff-andersen.no">
            kristian@hoff-andersen.no
          </a>
          <br />
          Phone: <a href="tel:+47 480 40 072">+47 480 40 072</a>
          <br />
          
            <a
              title="instagram"
              href="httphttps://www.instagram.com/hoffandersen/"
            >
              <div className="some_image instagram_logo" />
            </a>
            <a
              title="linked_in"
              href="https://www.linkedin.com/in/kristian-hoff-andersen-140b4a42/"
            >
              <div className="some_image linkedin_logo" />
            </a>
            <a title="facebook" href="https://www.facebook.com/hoffandersen">
              <div className="some_image facebook_logo" />
            </a>
          
        </div>

        <div className="item_container item_taller text_item">
          <h3>Fjord City tour</h3>
          The walking tour emphasizes how landscape and architecture have come
          together to create unique urban spaces in Oslo. This tour covers more
          than a thousand years of interplay between the city and the seafront.
        </div>

        <div className="item_container item_taller text_item">
          <h3>Nordic Classicism</h3>
          The tour emphasises how landscape and architecture have come together
          to create unique urban spaces in Oslo. This tour covers more than a
          thousand years of interplay between the city and the seafront.
        </div>

        <div className="item_container item_taller text_item">
          <h3>Blue-green tour</h3>
          The tour emphasises how landscape and architecture have come together
          to create unique urban spaces in Oslo. This tour covers more than a
          thousand years of interplay between the city and the seafront.
        </div>

        <div className="item_container text_item item_wide">
          <i>
            &quot;I was lucky to get invited to a walking city tour of Oslo hosted by
            architect Kristian Hoff-Andersen. The city walk was attended by
            classical architect Robert Adam. I was impressed by Kristian's
            curating, knowledge, and seamless guiding through the classical
            buildings in downtown Oslo.&quot;
          </i>
          <br />
          <br />- Julie Brundtland Løvseth
        </div>

        <div className="item_container item_image torshov_img" />
        <div className="item_container item_image oslo1_img" />
        <div className="item_container item_image oslo2_img" />
        <div className="item_container item_image oslo3_img" />
      </div>

      <div className="copyright">
        Copyright Kristian Hoff-Andersen 2024
        <br />
        Design by Thomas Flemming
      </div>
    </div>
  );
};
